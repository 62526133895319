<template>
  <div class="app">
    <NavigationElement :sections="navigationSections" />
    <div ref="frontpage">
      <FrontPage />
    </div>
    <div ref="aboutuspage">
      <Aboutuspage />
    </div>
    <div ref="gallerypage">
      <Gallerypage />
    </div>
    <div ref="pricingpage">
      <Pricingpage />
    </div>
    <div ref="footerpage">
      <Footerpage />
    </div>
  </div>
</template>

<script>
import Aboutuspage from './components/views/aboutuspage.vue';
import Footerpage from './components/views/footerpage.vue';
import FrontPage from './components/views/frontpage.vue';
import Gallerypage from './components/views/gallerypage.vue';
import Pricingpage from './components/views/pricingpage.vue';
import NavigationElement from './components/navigation/navigationelement.vue';


export default {
  name: 'App',
  components: {
    FrontPage,
    Aboutuspage,
    Footerpage,
    Gallerypage,
    Pricingpage,
    NavigationElement
  },

  data() {
    return {
      navigationSections: []
    }
  },

  mounted() {
    this.navigationSections = [
      {
        icon: "icons/house.png",
        text: "Etusivu",
        link: "#frontpage",
        ref: this.$refs.frontpage
      },
      {
        icon: "icons/about-us.png",
        text: "Tietoa minusta",
        link: "#about-us",
        ref: this.$refs.aboutuspage
      },
      {
        icon: "icons/gallery.png",
        text: "Galleria",
        link: "#gallery",
        ref: this.$refs.gallerypage
      },
      {
        icon: "icons/hair-services.png",
        text: "Kauneuspalvelut",
        link: "#services",
        ref: this.$refs.pricingpage
      },
      {
        icon: "icons/contact-us.png",
        text: "Ota yhteyttä",
        link: "#footer",
        ref: this.$refs.footerpage
      },
    ]
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.app {}
</style>
