<template>
    <div class="image-gallery">
        <div class="images">
            <v-lazy-image draggable="false" @click="selectImage(image.path)" class="image" :class="selectedImageClass(image.path)" :src="image.path" v-for="(image, i) in images" :key="'gi' + i" />
        </div>
    </div>
</template>
<script>

import VLazyImage from "v-lazy-image";

export default {
    name: "ImageGallery",
    components: {
        VLazyImage
    },
    props: {
        images: {
            default: []
        },
    },
    computed: {
        hasSelectedImage() {
            return this.selectedImage != "";
        },
        hasSelectedImageClass() {
            return this.hasSelectedImage ? "selected" : "not-selected";
        },
    },

    methods: {
        selectImage(path) {
            this.selectedImage = path;
            this.$emit("selected-image", this.selectedImage);
        },
        closeImage() {
            this.selectedImage = "";
        },
        isSelected(path) {
            return this.selectedImage == path;
        },
        selectedImageClass(path) {
            return this.isSelected(path) ? "selected" : "not-selected";
        }
    },

    data() {
        return {
            selectedImage: ""
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.v-lazy-image {
    opacity: 0;
    top: -30px;
}

.v-lazy-image-loaded {
    top: 0;
    opacity: 1;
}

.image-gallery {
    width: min-content;
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    margin: auto;

    .images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 40px;

        .image {
            height: 20vw;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 5px;

            transition: scale 0.2s, filter 0.2s, opacity 1s 0.3s, top 1s 0.3s, rotate 0.2s;
            filter: brightness(90%);

            &::selection {
                background: none;
            }

            &:hover,
            &.selected {
                scale: 1.1;
                z-index: 15;
                filter: brightness(100%);

                cursor: pointer;
            }

            &.selected {
                scale: 1.2;
                rotate: 5deg;
            }
        }

        @media all and (max-width: 1600px) {
            grid-template-columns: repeat(4, 25%);

            .image {
                height: 25vw;
            }
        }

        @media all and (max-width: 1280px) {
            grid-template-columns: repeat(3, 33.33%);

            .image {
                height: 33.33vw;
            }
        }

        @media all and (max-width: 800px) {
            flex-direction: column;
            grid-template-columns: auto;

            .image {
                height: 350px;
                width: 100vw;
                max-height: initial !important;
            }
        }
    }
}
</style>