<template>
    <a class="link-button button" :class="classType" :href="gotoLink">
        <img draggable="false" v-if="icon" :src="icon" class="icon" />
        <div class="text-container">
            <p class="text">{{ text }}</p>
        </div>
    </a>
</template>

<script>
export default {
    name: "LinkButton",

    props: {
        text: {
            type: String,
            default: "Example button text"
        },
        icon: {
            type: String,
            default: ""
        },
        classType: {
            type: String,
            default: ""
        },
        gotoLink: {
            type: String,
            default: "#",
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.link-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;

    min-width: 200px;
    height: 30px;
    border-radius: 50px;
    padding: 10px;

    background: $buttonColor1;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.342);
    transition: all 0.1s;
    filter: brightness(85%);

    @media all and (max-width: 800px),
    (max-height: 600px) {
        min-width: 150px;
        height: 20px;
    }

    &:hover {
        cursor: pointer;
        filter: brightness(100%);
    }

    .icon {
        width: 38px;
        aspect-ratio: 1;

        @media all and (max-width: 600px),
        (max-height: 600px) {
            width: 28px;
        }
    }

    .text-container {
        padding: 0 10px;
        width: 100%;

        .text {
            color: $textColor2;
            margin: auto;
            text-align: center;
        }
    }

    &.important {
        background: $buttonColor2;
    }
}
</style>