<template>
    <section v-if="imageGroups.length > 0" class="gallery-page" id="gallery">
        <div class="header fade-in-from-left">
            <img class="icon" src="icons/gallery.png">
            <h2>Galleria</h2>
        </div>
        <div class="fade-in-from-left">
            <button class="button show-images-button" :class="showImagesClass" @click="showImages = true">Näytä kuvat</button>
            <button class="button hide-images-button" :class="showImagesClass" @click="showImages = false">Piilota kuvat</button>
        </div>
        <ImageGallery v-if="showImages" ref="gallery" class="gallery" :class="showImagesClass" :images="images" @selected-image="selectImage" />
        <Imageviewer class="image-viewer" :selected-images="selectedImages" @closed-image="closeImage" />
    </section>
</template>

<script>

import ImageGallery from '@/components/gallery/imagegallery.vue';
import Imageviewer from '../gallery/imageviewer.vue';

export default {
    name: "GalleryPage",

    components: {
        ImageGallery,
        Imageviewer
    },

    methods: {
        selectImage(path) {
            this.selectedImages = this.imageGroups.find(imageGroup => imageGroup.find(image => image.path == path));
        },
        closeImage() {
            this.selectedImages = [];
            this.$refs.gallery.closeImage();
        }
    },

    computed: {
        showImagesClass() {
            return this.showImages ? "show-images" : "hide-images";
        },
        images() {
            return this.imageGroups.map(imageGroup => imageGroup[0]);
        }
    },

    data() {
        return {
            imageGroups: [],
            selectedImages: [],
            showImages: false
        }
    },

    created() {
        this.imageGroups = [
            [
                {
                    title: "Valmis kampaus",
                    path: "/images/gallery/img7-1.jpg"
                },
                {
                    title: "Valmis kampaus",
                    path: "/images/gallery/img7-2.jpg"
                },
                {
                    title: "Keskeneräinen kampaus",
                    path: "/images/gallery/img7-3.jpg"
                },
                {
                    title: "Keskeneräinen kampaus",
                    path: "/images/gallery/img7-4.jpg"
                },
            ],
            [
                {
                    title: "Valmis kampaus",
                    path: "/images/gallery/img5.jpg"
                },
                {
                    title: "Alkuperäinen kampaus",
                    path: "/images/gallery/img6.jpg"
                }
            ],
            [
                {
                    title: "Valmis kampaus",
                    path: "/images/gallery/img3.jpg"
                },
                {
                    title: "Alkuperäinen kampaus",
                    path: "/images/gallery/img4.jpg"
                }
            ],
            [
                {
                    title: "Valmis kampaus",
                    path: "/images/gallery/img2.jpg"
                },
                {
                    title: "Alkuperäinen kampaus",
                    path: "/images/gallery/img1.jpg"
                }
            ],
            [
                {
                    title: "Kampaamon sisätilat",
                    path: "/images/gallery/img9-1.jpg"
                },
                {
                    title: "Kampaamon sisätilat",
                    path: "/images/gallery/img9-2.jpg"
                }
            ],
            [
                {
                    title: "Valmis kampaus",
                    path: "/images/gallery/img10-1.jpg"
                },
                {
                    title: "Alkuperäinen kampaus",
                    path: "/images/gallery/img10-2.jpg"
                }
            ],
            [
                {
                    title: "Valmis kampaus",
                    path: "/images/gallery/img11-1.jpg"
                },
                {
                    title: "Alkuperäinen kampaus",
                    path: "/images/gallery/img11-2.jpg"
                }
            ],
        ]
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.gallery-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 0;
    padding-bottom: 80px;
    background: $background2;
    overflow: hidden;
    gap: 40px;

    .image-viewer {
        z-index: 100;
    }

    .show-images-button {
        display: none;

        &.hide-images {
            display: block;
        }
    }

    .hide-images-button {
        display: block;

        &.hide-images {
            display: none;
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .icon {
            height: 50px;
            aspect-ratio: 1;
        }
    }
}
</style>