<template>
    <footer class="footer" id="footer">
        <div class="dashed-lines">
            <div class="lines mobile-1">
                <div class="dashed-line">
                    <div v-for="i in 20" :key="'dash' + i" class="dash"></div>
                </div>
            </div>
            <div class="lines no-mobile">
                <div class="dashed-line">
                    <div v-for="i in 40" :key="'dash' + i" class="dash"></div>
                </div>
            </div>
            <img class="icon fade-in-from-left" src="icons/scissors-to-right.png">
        </div>
        <h2 class="main-title fade-in-from-top">Yhteystiedot</h2>
        <p class="main-title-description text fade-in-from-top">Kauneuspalvelumme ovat avoinna <b>ma–su</b> klo <b>13–23</b>, ja aamuaikoja saa tarvittaessa.</p>
        <p class="main-title-description text fade-in-from-top">Psykologimme vastaanottoajat ovat <b>ma–pe</b> klo <b>10–12</b>.</p>
        <p class="main-title-description underlined help-text fade-in-from-top">Voit varata ajan soittamalla tai lähettämällä viestiä. Tervetuloa!</p>
        <div class="info">
            <div class="column fade-in-from-left">
                <h4 class="title">Osoite</h4>
                <div class="icon-row">
                    <img class="icon" src="icons/house.png">
                    <p class="text">Piettasenkatu 26 B 13</p>
                </div>
                <div class="icon-row">
                    <img class="icon" src="icons/location.png">
                    <p class="text">33580 Tampere</p>
                </div>
                <div class="icon-row">
                    <img class="icon" src="icons/phone.png">
                    <p class="text">45 880 7111</p>
                </div>
                <div class="icon-row">
                    <img class="icon" src="icons/mail.png">
                    <p class="text">miia.heinola@gmail.com</p>
                </div>
                <a class="button" href="mailto:miia.heinola@gmail.com">Lähetä viesti</a>
            </div>
            <div class="map fade-in-from-right">
                <l-map ref="map" v-model:zoom="zoom" v-model:center="mapCenter" @ready="onMapReady">
                    <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap"></l-tile-layer>
                    <l-marker :lat-lng="homeMarkerLatLng">
                        <l-icon :icon-url="'icons/home-marker.png'" :icon-size="[41, 41]" :icon-anchor="[12, 41]"></l-icon>
                        <l-tooltip :content="'Kotikampaamo'" direction="bottom"></l-tooltip>
                    </l-marker>
                    <l-marker :lat-lng="[61.4972, 23.9055]">
                        <l-icon :icon-url="'icons/car-parking-marker.png'" :icon-size="[41, 41]" :icon-anchor="[12, 41]"></l-icon>
                        <l-tooltip :content="'Vierasparkkipaikka'" direction="bottom"></l-tooltip>
                    </l-marker>
                    <l-control position="topleft" class="leaflet-bar leaflet-control icon-control center-map" @click="resetMapPosition()">
                        <img class="icon" src="icons/center.png">
                    </l-control>
                    <l-control position="topleft" class="leaflet-bar leaflet-control icon-control fullscreen" @click="openGoogleMapsRoute()">
                        <img class="icon" src="icons/route.png">
                    </l-control>
                </l-map>
            </div>
        </div>
    </footer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */

import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LIcon, LTooltip, LControl } from '@vue-leaflet/vue-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";

export default {
    name: "FooterPage",

    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LTooltip,
        LControl
    },

    methods: {
        onMapReady(map) {
            const fullscreenControl = new L.Control.Fullscreen({
                position: 'topright',
            });
            map.addControl(fullscreenControl);
        },
        openGoogleMapsRoute() {
            const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${this.homeMarkerLatLng[0]},${this.homeMarkerLatLng[1]}`;
            window.open(googleMapsUrl, '_blank');
        },
        resetMapPosition() {
            this.mapCenter = this.defaultCenterLatLng;
        }
    },

    data() {
        return {
            homeMarkerLatLng: [61.49663, 23.90292],

            defaultZoom: 17,
            defaultCenterLatLng: [61.49685, 23.9043],

            zoom: 16,
            mapCenter: [0, 0],
        };
    },

    created() {
        this.mapCenter = this.defaultCenterLatLng;
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.icon-control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    left: 1px;
    padding: 2px;
    aspect-ratio: 1;
    background: white;
    outline: 2px solid rgba(0, 0, 0, 0.192);
    border-color: none;
    border-width: 0;
    cursor: pointer;

    .icon {
        width: 100%;
        aspect-ratio: 1;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 500px;
    background: $footerColor;
    padding: 20px 0;

    .dashed-lines {
        position: absolute;
        right: 0;
        width: 100%;
        top: 0;

        .lines {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 97.3%;
            height: 3px;
            right: 0;

            .dashed-line {
                width: 100%;
                height: 100%;
            }


            &.mobile-1 {
                display: none;
            }

            @media all and (max-width: 800px) {
                &.no-mobile {
                    display: none;
                }

                &.mobile-1 {
                    display: block;

                    .dash {
                        width: 15px;
                    }
                }
            }

            @media all and (max-width: 500px) {
                width: 93%;

                &.mobile-1 {
                    .dash {
                        width: 5px;
                    }
                }
            }
        }

        .icon {
            position: absolute;
            height: 3em;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            filter: hue-rotate(50deg) brightness(40%);
        }
    }

    .main-title {
        text-align: center;
    }

    .main-title-description {
        margin-top: 10px;
        text-align: center;

        &.underlined {
            text-decoration: underline;
        }
    }

    .map {
        width: 350px;
        max-width: 90%;
        aspect-ratio: 1;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.39);
        margin: auto;
    }

    .info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 50px;
        gap: 100px;
        max-width: 100%;

        @media all and (max-width: 800px) {
            flex-direction: column;

            padding-left: 0;
            padding-right: 0;

            .column {
                align-items: center;
                margin: auto;

                .icon-row {
                    width: 100%;
                }
            }
        }

        .column {
            width: min-content;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .title {
                text-align: center;
                margin-bottom: 20px;
            }

            .icon-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .text {
                    text-wrap: nowrap;
                    white-space: nowrap;
                    word-break: keep-all;
                }

                .icon {
                    height: 25px;
                    aspect-ratio: 1;
                }
            }

            .button {
                margin-top: 15px;
            }
        }
    }
}
</style>