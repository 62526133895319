
import { createApp } from 'vue'
import App from './App.vue'

import '@/assets/styles/variables.scss';
import '@/assets/styles/main.scss';

const app = createApp(App)

console.log("Website made by: Nico Heinola")

app.mount('#app')