<template>
    <div class="pricing-card">
        <div class="header">
            <h3 class="title">{{ title }}</h3>
            <p class="help-text">{{ description }}</p>
        </div>
        <div class="products">
            <div class="product" v-for="product, i in products" :key="'prod' + i">
                <div class="name-container">
                    <img class="icon" :class="!product.icon ? 'no' : ''" :src="product.icon" />
                    <p class="name" :class="product.offer ? 'has-offer' : ''">{{ product.name }}</p>
                </div>
                <div class="price-container">
                    <div class="offer" :class="!product.offer ? 'no' : ''" v-if="product.priceWithAlv || product.price"></div>
                    <p class="price" v-if="product.priceWithAlv || product.price">{{ product.priceWithAlv ? product.priceWithAlv.toFixed(2) : product.price.toFixed(2) }} € {{ product.pricePrefix }}</p>
                </div>
                <div class="sub-products">
                    <div class="product" v-for="subProduct, i2 in product.subProducts" :key="'subprod' + i2 + '-' + i">
                        <div class="name-container">
                            <p class="name" :class="subProduct.offer ? 'has-offer' : ''">{{ subProduct.name }}</p>
                        </div>
                        <div class="price-container">
                            <div class="offer" :class="!subProduct.offer ? 'no' : ''"></div>
                            <p class="price">{{ subProduct.priceWithAlv ? subProduct.priceWithAlv.toFixed(2) : subProduct.price.toFixed(2) }} €</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <a v-if="infoButton.visible" :target="infoButton.target" :href="infoButton.link" class="button center-button">{{ infoButton.text }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "PricingCard",

    props: {
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        products: {
            default: [
            ]
        },
        infoButton: {
            default: {
                visible: false,
                text: "",
                link: ""
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.pricing-card {
    @import "@/assets/styles/variables.scss";

    display: flex;
    flex-direction: column;

    max-width: 100%;
    width: 500px;

    background: $cardBackground;
    border-radius: 10px;
    box-shadow: 0 0 15px 1px rgba(117, 117, 117, 0.308);

    padding-bottom: 20px;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        padding: 15px;
        height: 60px;
        border-radius: 10px 10px 0 0;

        background: $cardHeaderBackground;
    }

    .buttons {
        margin: auto;
        margin-bottom: initial;

        width: 100%;

        .center-button {
            max-width: 300px;
            width: 100%;
            margin: 0 auto;
            margin-top: 20px;
        }
    }

    .products {
        display: flex;
        flex-direction: column;

        gap: 10px;
        padding: 20px;
        padding-bottom: 0;

        @media all and (max-width: 360px) {
            padding: 5px;
        }


        .product {
            display: flex;
            flex-direction: row;

            white-space: nowrap;
            flex-wrap: wrap;

            justify-content: space-between;
            align-items: center;

            font-weight: normal;


            .name-container {
                display: flex;
                flex-direction: row;
                align-items: center;

                .icon {
                    width: 30px;
                    margin-right: 5px;
                    aspect-ratio: 1;
                    pointer-events: none;

                    @media all and (max-width: 360px) {
                        width: 20px;
                    }

                    &.no {
                        opacity: 0;
                    }
                }

                .name {
                    &.has-offer {
                        font-weight: bold;
                    }
                }
            }

            .price-container {
                display: flex;
                flex-direction: row;
                align-items: center;

                .offer {
                    width: 25px;
                    aspect-ratio: 1;

                    background-image: url("~/public/icons/deal.png");
                    background-size: contain;
                    background-repeat: no-repeat;

                    rotate: 90deg;
                    margin-right: 10px;

                    &.no {
                        display: none;
                    }
                }

                .offer:not(.no)~.price {
                    font-weight: bold;
                    text-decoration: underline;
                }
            }

            .sub-products {
                flex-basis: 100%;

                .product::before {
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 40px;
                    transform: translate(0, -50%);
                    border: 2px solid $textColor1;
                    border-radius: 50%;
                    width: 4px;
                    aspect-ratio: 1;
                }

                .product {
                    margin-top: 10px;
                    font-weight: normal;

                    .name {
                        margin-left: 57px;
                    }

                    .price {
                        text-decoration: none;
                    }
                }
            }
        }
    }

}
</style>