<template>
    <a class="navigation-link" :class="activeClass" :href="link">
        <img class="icon" :src="icon" />
        <div class="text-container">
            <p class="text">{{ text }}</p>
        </div>
    </a>
</template>

<script>
export default {
    name: "NavigationLink",

    props: {
        icon: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        link: {
            type: String,
            default: "#",
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        activeClass() {
            return this.isActive ? "active" : "";
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.navigation-link {

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;

    &:hover {
        .icon {
            scale: 0.9;
            rotate: 5deg;
        }

        background: $navLinkBackground;
    }

    &.active {
        .icon {
            scale: 1;
        }
    }

    @media all and (max-width: 700px) {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        padding: 10px;

        &.active {
            background: $navLinkBackground;
        }
    }

    .icon {
        width: 80%;
        scale: 0.8;
        transition: all 0.2s;

        @media all and (max-width: 700px) {
            height: 40px;
            width: initial;
            aspect-ratio: 1;
        }
    }

    .text-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: absolute;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 0;

        padding-left: 100%;
        border-radius: 0 50px 50px 0;

        width: max-content;
        overflow: hidden;

        transition: all 0.2s ease-in-out;

        @media all and (min-width: 700px) {
            &:hover {
                max-width: 200px;
                cursor: pointer;
            }
        }

        @media all and (max-width: 700px) {
            position: relative;
            max-width: initial;
            width: 100%;
            height: 100%;

            padding-left: 0;
            border-radius: 0;
        }

        .text {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            background: $navLinkBackground;
            height: 100%;
            color: $navTextColor;
            white-space: nowrap;

            &::selection {
                background: none;
            }

            @media all and (max-width: 700px) {
                width: 100%;
                background: none;
                text-align: left;
                justify-content: flex-start;
            }
        }
    }
}
</style>