<template>
    <div class="view-image" :class="hasSelectedImageClass">
        <div @click="closeImage" class="darkened-bg"></div>
        <div class="view">
            <div class="image-container" :class="selectedImageClass(index)" v-for="(image, index) in selectedImages" :key="'image2' + image.path">
                <img class="image" :class="selectedImageClass(index)" draggable="false" :src="image.path">
            </div>
            <button class="button close-button" @click="closeImage">X</button>
            <h2 class="title">{{ currentImage.title }}</h2>
            <div class="previews">
                <img class="preview" :class="selectedImageClass(index)" draggable="false" :src="image.path" @click="selectedImageIndex = index" v-for="(image, index) in selectedImages" :key="'img:' + image.path" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImageViewer",

    props: {
        selectedImages: {
            default: []
        },
    },

    computed: {
        hasSelectedImage() {
            return this.$props.selectedImages.length > 0;
        },
        hasSelectedImageClass() {
            return this.hasSelectedImage ? "selected" : "not-selected";
        },
        currentImage() {
            if (!this.hasSelectedImage) {
                return "";
            }

            return this.selectedImages[this.selectedImageIndex];
        }
    },
    watch: {
        selectedImage(newValue) {
            if (newValue != "") {
                this.selectedImageFunc();
            }
        },
    },
    methods: {
        selectedImageFunc() {
            document.body.style.overflow = 'hidden';
        },
        closeImage() {
            document.body.style.overflow = "";
            this.selectedImageIndex = 0;
            this.$emit("closed-image");
        },
        selectedImageClass(index) {
            return this.selectedImageIndex == index ? "selected" : "not-selected";
        },
    },

    created() {
    },

    data() {
        return {
            selectedImageIndex: 0
        }
    },
}
</script>

<style lang="scss" scoped>
.view-image {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    overflow: auto;

    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;

    &.selected {
        opacity: 1;
        pointer-events: all;

        .darkened-bg {
            pointer-events: all;
        }
    }

    .view {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        height: 95%;
        border-radius: 5px;
        overflow: hidden;

        .title {
            position: absolute;
            top: 0;
            color: white;
            text-shadow: 0 0 15px black, 0 0 15px black;
        }

        .image-container {
            position: absolute;
            left: 0;
            top: 0;

            height: 100%;

            &.selected {
                position: relative;

                .image {
                    opacity: 1;
                }
            }

            .image {
                position: relative;
                height: 100%;
                box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.445);
                opacity: 0;
                transition: all 0.2s;
            }
        }

        .previews {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 80px;
            background: rgba(158, 158, 158, 0.767);
            bottom: 0px;
            overflow-x: auto;

            .preview {
                height: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                transition: all 0.2s;
                cursor: pointer;

                filter: brightness(70%);

                &:hover {
                    filter: brightness(85%);
                }

                &.selected {
                    filter: brightness(100%);
                }
            }
        }

        @media all and (max-width: 800px),
        (max-height: 600px) {
            width: 100%;
            height: initial;

            .image {
                height: initial;
                width: 100%;
            }
        }
    }

    .close-button {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0;
        width: 50px;
        text-align: center;
        border-bottom-left-radius: 10px;
        background: rgb(255, 97, 97);
    }

    .darkened-bg {
        position: fixed;
        width: 100vw;
        height: 100%;
        background: rgba(0, 0, 0, 0.774);
        pointer-events: none;
        transition: all 0.7s;
    }
}
</style>