<template>
    <section class="about-us" id="about-us">
        <div class="content">
            <div class="header fade-in-from-top">
                <img class="icon" src="icons/about-us.png">
                <h2>Tietoa minusta</h2>
            </div>
            <div class="text-container">
                <img class="icon fade-in-from-left" src="images/me_upscaled.jpg">
                <p class="info fade-in-from-bottom">Hei, olen toiminut parturi-kampaajana yli vuosikymmenen ja erityisosaamistani ovat hiusten pidennykset. Minulla on mukava pieni kotikampaamo, Mia's Beauty and Hair, minne jokainen on lämpimästi tervetullut. Palvelumme kattavat monenlaiset hiuspalvelut mukaan lukien hiustenleikkaukset, pidennykset ja värjäykset. Kotikampaamossa on viihtyisä ja kotoinen tunnelma, ja kauneus on hyvinvointia, ja meille jokainen on kaunis.</p>
            </div>
            <div class="decorations">
                <div class="container fade-in-from-left">
                    <div class="hollow-circle dec dec-1"></div>
                </div>
                <div class="container fade-in-from-left">
                    <div class="filled-circle dec dec-2"></div>
                </div>
                <div class="container fade-in-from-right">
                    <div class="hollow-circle dec dec-3"></div>
                </div>
                <div class="container fade-in-from-right">
                    <div class="filled-circle dec dec-4"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutUsPage"
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: $pageWidth;
    height: 450px;
    max-height: 100vh;
    margin: auto;

    @media all and (max-width: 400px) {
        max-width: 90%;
    }

    @media all and (max-width: 750px) {
        padding: 30px 0;
    }

    .decorations {
        position: absolute;
        width: calc(100% + 400px);
        height: calc(100% + 100px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        @media all and (max-width: 1100px) {
            width: calc(100% + 200px);
            height: calc(100% + 100px);
        }

        @media all and (max-width: 850px) {
            width: calc(100%);
            height: calc(100%);
        }

        @media all and (max-width: 320px),
        (max-height: 400px) and (max-width: 500px),
        (max-height: 350px) and (max-width: 850px) {
            display: none;
        }

        .container {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .dec {
            position: absolute;
        }

        .dec-1 {
            left: 10px;
            top: 40px;
            width: 30px;
            border-color: $buttonColor1;

            @media all and (max-width: 850px) {
                left: 30px;
                top: -60px;
            }
        }

        .dec-2 {
            left: 50px;
            top: 10px;
            width: 30px;
            background: $buttonColor2;

            @media all and (max-width: 850px) {
                left: 75px;
                top: -70px;
            }
        }

        .dec-3 {
            right: 10px;
            bottom: 40px;
            width: 30px;
            border-color: $buttonColor2;

            @media all and (max-width: 850px) {
                right: 30px;
                bottom: -60px;
            }
        }

        .dec-4 {
            right: 50px;
            bottom: 10px;
            width: 30px;
            background: $buttonColor1;

            @media all and (max-width: 850px) {
                right: 75px;
                bottom: -70px;
            }
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        margin-bottom: 40px;

        @media all and (max-width: 850px) {
            justify-content: center;
        }

        .icon {
            height: 50px;
            aspect-ratio: 1;
        }
    }

    .text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 100vw;
        width: 700px;
        gap: 60px;

        .icon {
            width: 150px;
            aspect-ratio: 1;
            border-radius: 50%;
        }

        .info {
            width: 100%;
        }

        @media all and (max-width: 750px) {
            flex-direction: column;
            gap: 10px;

            .icon {
                width: 100px;
            }

            .info {
                text-align: center;
            }
        }
    }
}
</style>