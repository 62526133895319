<template>
    <nav class="navigation fade-in-from-top-large" :class="isNavOpenClass + ' ' + navHasBackgroundClass">
        <div class="darkened-bg"></div>
        <div class="mobile-only burger-menu" :class="isNavOpenClass + ' ' + navHasBackgroundClass">
            <div class="burger" :class="isNavOpenClass + ' ' + navHasBackgroundClass" @click="toggleOpen">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>
        <div class="links" :class="isNavOpenClass">
            <NavigationLink :icon="section.icon" :text="section.text" :link="section.link" class="link" :is-active="isActive(section)" v-for="(section, i) in sections" :key="'section' + i" />
        </div>
    </nav>
</template>

<script>
import NavigationLink from './navigationlink.vue';

export default {
    name: "NavigationElement",
    components: { NavigationLink },
    props: {
        sections: {
            default: []
        },
    },
    computed: {
        isNavOpenClass() {
            return this.isNavOpen ? "open" : "closed";
        },
        navHasBackgroundClass() {
            return this.navHasBackground ? "has-bg" : "";
        }
    },
    methods: {
        isActive(section) {
            return this.activeSection === section;
        },
        toggleOpen() {
            document.body.style.overflow = !this.isNavOpen ? 'hidden' : "";

            this.isNavOpen = !this.isNavOpen;
        },
        setActiveSection(section) {
            this.activeSection = section
        },
        handleScroll() {
            const scrollPosition = window.scrollY;

            this.navHasBackground = scrollPosition > 10;

            const documentHeight = document.documentElement.scrollHeight;
            if (scrollPosition + window.innerHeight >= documentHeight - 200) {
                this.setActiveSection(this.sections[this.sections.length - 1])
                return;
            }

            let activeSection = null;
            for (const section of this.sections) {
                // Get the section info
                const sectionRef = section.ref;
                const refScrollPosition = sectionRef.offsetTop;

                // Check if the section is active in the view 
                if (scrollPosition >= refScrollPosition - 10) {
                    activeSection = section;
                }
            }
            this.setActiveSection(activeSection);
        },
    },

    data() {
        return {
            activeSection: null,
            isNavOpen: false,
            navHasBackground: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.navigation {
    position: fixed;
    width: 50px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.445);

    top: 0;
    left: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;

    padding-bottom: 40px;

    z-index: 100;

    background: $navBackground;

    transition: all 0.2s;
    transition-delay: 0s;

    .darkened-bg {
        display: none;
    }

    @media all and (max-width: 960px) {
        left: 0;
    }

    @media all and (max-width: 700px) {
        width: 100%;
        border-radius: 0;
        padding: 0;

        &:not(.open) {
            padding-bottom: 0;
            transition-delay: 0.2s;
        }

        &:not(.open):not(.has-bg) {
            background: none;
            box-shadow: none;
        }

        &.open {
            pointer-events: all;
            background: none;

            .darkened-bg {
                opacity: 1;
                pointer-events: all;
            }
        }

        .darkened-bg {
            display: block;
            position: fixed;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.651);

            transition: all 0.2s;
            pointer-events: none;
        }
    }

    @media all and (min-width: 700px) {
        .mobile-only {
            display: none !important;
        }
    }

    .burger-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        width: 100;
        height: 40px;

        background: none;
        transition: all 0.2s;

        &.closed {
            transition-delay: 0.35s;
        }

        &.open {
            background: $navBackground;
            transition-duration: 0.1s;
            transition-delay: 0s;
        }

        .burger {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 4px;
            width: 30px;

            &:hover {
                cursor: pointer;
            }

            .line {
                width: 100%;
                height: 5px;
                background: black;
                border-radius: 50px;
                transition: all 0.2s;
                transition-delay: 0.2s;
            }

            &:not(.open):not(.has-bg) {
                .line {
                    background: white;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.411);
                }
            }
        }
    }

    .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @media all and (max-width: 700px) {
            gap: 10px;
            width: 100%;

            max-height: 0;
            overflow: hidden;

            background: $navBackground;

            transition: all 0.2s;
            padding: 0;

            &.open {
                padding: 20px 0;
                max-height: 500px;
            }
        }
    }
}
</style>