<template>
    <div class="decorated-title">
        <div class="animated-container">
            <h1 class="title">{{ title }}</h1>
        </div>
        <div class="decorations">
            <img draggable="false" :src="iconPath" class="icon">
            <div class="line line-1"></div>
            <div class="line line-2"></div>
            <div class="line line-3"></div>
            <div class="line line-4"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DecoratedTitle",
    props: {
        title: {
            type: String,
            default: "Example title"
        },
        iconPath: {
            type: String,
            default: "icons/scissors.png"
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.decorated-title {

    .animated-container {
        animation: animatedIn 0.9s ease-in-out;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
        margin: auto;
        overflow: hidden;
        width: 0;

        @keyframes animatedIn {
            from {
                width: 0;
            }

            to {
                width: 100%;
            }
        }
    }

    .title {
        color: $textColor2;
        text-shadow: 2px 2px 15px black;
        word-break: keep-all;
        white-space: nowrap;
        word-wrap: nowrap;
    }

    .decorations {
        position: absolute;
        width: 0;
        height: calc(100% + 40px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        animation: animatedIn 0.9s ease-in-out;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
        margin: auto;

        @keyframes animatedIn {
            to {
                width: calc(100% + 40px);
            }
        }

        $line_size_1: 40%;
        $line_size_2: 3px;

        @media all and (max-width: 400px),
        (max-height: 600px) {
            height: calc(100% + 25px);

            @keyframes animatedIn {
                to {
                    width: calc(100% + 30px);
                }
            }
        }

        .icon {
            position: absolute;
            width: 50px;
            aspect-ratio: 1;
            left: -35px;
            top: -35px;

            @media all and (max-width: 400px),
            (max-height: 600px) {
                width: 30px;
                left: -25px;
                top: -25px;
            }
        }

        .line {
            position: absolute;
            border-radius: 50px;
            background: $textColor2;
            box-shadow: 0 0 15px 1px black;
        }

        .line-1 {
            width: $line_size_1;
            height: $line_size_2;
        }

        .line-2 {
            height: $line_size_1;
            width: $line_size_2;
        }

        .line-3 {
            width: $line_size_1;
            height: $line_size_2;
            right: 0;
            bottom: 0;
        }

        .line-4 {
            height: $line_size_1;
            width: $line_size_2;
            right: 0;
            bottom: 0;
        }
    }
}
</style>