<template>
    <div class="pricing-page" id="services">
        <div class="header fade-in-from-top">
            <img class="icon" src="icons/hair-services.png">
            <h2 class="title">Kauneuspalvelut</h2>
        </div>
        <p class="description help-text fade-in-from-top">Voit huoletta ja maksutta pyytää konsultaatio-käyntiä tai laittaa minulle viestiä, jossa käymme läpi kauneuspalvelun tarpeen.</p>
        <b class="description help-text fade-in-from-top">Teen myös kotikäyntejä <a target="_blank" href="https://www.tammenlehvakeskus.fi">Tammenlehvän palvelukodeille</a>, parantolankatu 11-13, Tampere. Kotikäynnin hinta on 25e.</b>
        <div class="cards">
            <Pricingcard class="fade-in-from-bottom" :infoButton="productCard.infoButton" :title="productCard.title" :products="productCard.products" :description="productCard.description" v-for="productCard, i in productCards" :key="i + 'prodc'"></Pricingcard>
        </div>
    </div>
</template>

<script>
import Pricingcard from '../pricingcard/pricingcard.vue';

export default {
    name: "PricingPage",
    components: { Pricingcard },
    data() {
        return {
            productCards: [
                {
                    title: "Hiustenleikkuut, -pesut ja parrat",
                    products: [
                        {
                            name: "Perus hiustenleikkuu",
                            priceWithAlv: 25,
                            icon: "icons/hair-cut.png",
                            subProducts: [
                                {
                                    name: "Pesun kanssa",
                                    priceWithAlv: 35,
                                    offer: true
                                },
                                {
                                    name: "Parranajon kanssa",
                                    priceWithAlv: 35,
                                    offer: true
                                },
                            ]
                        },
                        {
                            name: "Hiustenpesu",
                            priceWithAlv: 15.00,
                            icon: "icons/hair-wash.png",
                            subProducts: []
                        },
                        {
                            name: "Parran siisitimen ja ajo",
                            priceWithAlv: 15.00,
                            icon: "icons/beard-trimming.png",
                            subProducts: []
                        },
                    ]
                },
                {
                    title: "Ripset ja kulmat",
                    products: [
                        {
                            name: "Ripsien värjäys",
                            priceWithAlv: 20,
                            icon: "icons/eyelash.png"
                        },
                        {
                            name: "Kulmien värjäys",
                            priceWithAlv: 10,
                            icon: "icons/eyebrow.png"
                        },
                        {
                            name: "Kulmien muotoilu",
                            icon: "icons/eyebrow-shape.png",
                            priceWithAlv: 5,
                        },
                        {
                            name: "Kaikki ylläolevat",
                            priceWithAlv: 25,
                            offer: true,
                        },
                    ]
                },
                {
                    title: "Hiusten värjäykset",
                    description: "Hiustenleikkaus sisältyy hintaan",
                    products: [
                        {
                            name: "Lyhyet",
                            priceWithAlv: 100,
                            icon: "icons/short-hair.png"
                        },
                        {
                            name: "Puolipitkät",
                            priceWithAlv: 110,
                            icon: "icons/medium-hair.png"
                        },
                        {
                            name: "Pitkät",
                            priceWithAlv: 120,
                            icon: "icons/long-hair.png"
                        },
                        {
                            name: "Lisäpalvelut:",
                            offer: true,
                            subProducts: [
                                {
                                    name: "Raidoitus + Monivärjäyslisä",
                                    priceWithAlv: 15
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Pidennykset",
                    description: "Huomioithan, että hintaan lisätään hiusten hinta erikseen ja hinta veloitetaan etukäteen ennen tilauksen tekoa",
                    products: [
                        {
                            name: "Hiuspidennysten laitto",
                            priceWithAlv: 70,
                            pricePrefix: "/ h",
                            icon: "icons/hair-extension.png"
                        }
                    ],
                    infoButton: {
                        visible: true,
                        target: "_blank",
                        text: "Avaa hiusten hinnat",
                        link: "documents/pidennyshiusten_hinnat.pdf"
                    }
                },
                {
                    title: "Psykologi palvelut",
                    description: "Psykologi-palveluihin ei sisälly kelakorvausta",
                    products: [
                        {
                            name: "Psykologikäynti (Timo Nieminen)",
                            priceWithAlv: 50,
                            pricePrefix: "/ h",
                            icon: "icons/therapy.png"
                        }
                    ],
                    infoButton: {
                        visible: true,
                        target: "",
                        text: "Varaa aika",
                        link: "#footer"
                    }
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.pricing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 0;
    min-height: 100vh;

    .header {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 20px;

        @media all and (max-width: 850px) {
            justify-content: center;
        }

        .icon {
            height: 50px;
            aspect-ratio: 1;
        }
    }

    .description {
        text-align: center;
        margin: 3px 0;

        a {
            text-decoration: underline;
        }
    }

    .cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;

        margin-top: 40px;
    }

}
</style>