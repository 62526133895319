<template>
    <section class="frontpage" id="frontpage">
        <div class="bg-image-container">
            <div draggable="false" class="background-image placeholder"></div>
            <v-lazy-image draggable="false" class="background-image" src="/images/frontpage_smaller.jpg" src-placeholder="/images/frontpage_placeholder.jpg" />
        </div>
        <DecoratedTitle class="title fade-in" title="Mia's hair and beauty" />
        <p class="info-text fade-in-from-left">Värit, leikkuut ja muut kauneuspalvelut</p>
        <div class="divider fade-in-from-left">
            <img class="icon" src="icons/down-arrow.png" />
        </div>
        <div class="buttons">
            <LinkButton class="contact-us fade-in-from-top" classType="important" text="Varaa aika" icon="icons/contact-us.png" gotoLink="#footer" />
        </div>
    </section>
</template>

<script>

import DecoratedTitle from "../title/decoratedtitle";
import LinkButton from "../buttons/linkbutton/linkbutton";
import VLazyImage from "v-lazy-image";

export default {
    name: "FrontPage",
    components: {
        DecoratedTitle,
        LinkButton,
        VLazyImage
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/images.scss";

.v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
}

.v-lazy-image-loaded {
    filter: blur(0);
}

.frontpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;

    /*@media all and (max-height: 500px) and (max-width: 600px),
    (max-height: 350px) {
        height: 130vh;
    }

    @media all and (max-height: 400px) and (max-width: 600px),
    (max-height: 300px) {
        height: 150vh;
    }

    @media all and (max-height: 300px) and (max-width: 600px),
    (max-height: 250px) {
        height: 200vh;
    }*/

    @media all and (max-height: 530px) {
        height: 100vh;
    }

    @media all and (max-height: 250px) {
        height: 160vh;
    }

    * {
        color: $textColor2;
    }

    .buttons {
        display: grid;
        grid-template-columns: auto auto auto;
        row-gap: 30px;

        &>* {
            margin: 0 15px;
        }

        @media all and (max-width: 800px) and (min-height: 500px),
        (max-width: 650px) {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
    }

    .contact-us {
        grid-column: 2;
    }

    .title {
        margin-bottom: 35px;
    }

    .info-text {
        text-align: center;
    }

    .divider {

        .icon {
            width: 40px;
            margin-top: 0px;
            aspect-ratio: 1;
        }
    }

    .bg-image-container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;
        overflow: hidden;
        filter: brightness(40%);

        .background-image {
            position: absolute;
            width: 120%;
            height: 120%;
            object-fit: cover;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            filter: hue-rotate(325deg);

            &.placeholder {
                background-image: url($frontPageLowRes);
            }
        }
    }
}
</style>